<template>
  <docs-layout>
    <div class="space-y-4 pb-10 w-full text-left">
      <p class="font-bold">Default</p>
      <div class="w-2/4">
        <asom-multi-select
          v-model="values"
          :options="options"
          placeholder="Select an multi options"
        />
      </div>
      <p>Value: {{ values }}</p>
      <p class="font-bold">States</p>
      <div class="w-1/2 space-y-10">
        <asom-multi-select
          state="default"
          v-model="value2"
          :options="options"
        />
        <asom-multi-select
          state="error"
          v-model="value4"
          :options="options"
        />
      </div>
      <p class="font-bold">Disabled</p>
      <div class="w-1/4">
        <asom-multi-select
          disabled
          v-model="value5"
          :options="options"
        />
      </div>
    </div>
  </docs-layout>
</template>

<script>
import AsomMultiSelect from "@/components/input/multi_select/AsomMultiSelect.vue";
import DocsLayout from "@/docs/DocsLayout.vue";
export default {
  components: {
    DocsLayout,
    AsomMultiSelect,
  },
  data() {
    return {
      options: [
        { value: "1", label: "Option 1" },
        { value: "2", label: "Option 2" },
        { value: "3", label: "Option 3" },
        { value: "4", label: "Option 4" },
        { value: "5", label: "Option 5" },
        { value: "6", label: "Option 6" },
        { value: "7", label: "Option 7" },
        { value: "8", label: "Option 8" },
        { value: "9", label: "Option 9" },
        { value: "10", label: "Option 10" },
        { value: "11", label: "Option 11" },
        { value: "12", label: "Option 12" },
        { value: "13", label: "Option 13" },
      ],
      values:  [
        { value: "1", label: "Option 1" },
        { value: "2", label: "Option 2" },
        { value: "3", label: "Option 3" },
        { value: "4", label: "Option 4" },
        { value: "5", label: "Option 5" },
        { value: "6", label: "Option 6" },
        { value: "7", label: "Option 7" },
        { value: "8", label: "Option 8" },
      ],
      value2: null,
      value3: null,
      value4: null,
      value5:  [
        { value: "1", label: "Option 1" },
        { value: "3", label: "Option 3" },
      ],
    };
  },
};
</script>
