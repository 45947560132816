<template>
  <docs-layout> 
    <div class="space-y-4 pb-10 w-full text-left">
      <p class="font-bold">Use library @vueform/multiselect</p>
      <p class="font-bold">Default</p>
      <div class="w-1/4">
        <asom-input-select
          v-model="value1"
          placeholder="Select an option"
          :options="options"
        />
      </div>
      <p>Value: {{value1}}</p>
      <p class="font-bold">States</p>
      <div class="w-1/4 space-y-10">
        <asom-input-select
          state="default"
          v-model="value2"
          :options="options"
        />
        <asom-input-select
          state="error"
          v-model="value4"
          :options="options"
        />
      </div>
      <p class="font-bold">Disabled</p>
      <div class="w-1/4">
        <asom-input-select
          disabled
          v-model="value5"
          :options="options"
        />
      </div>
    </div>
  </docs-layout>
</template>
<script>
import DocsLayout from '@/docs/DocsLayout.vue'
export default {
  components: {
    DocsLayout,
  },
  data() {
    return {
      options: [
        { value: '1', label: 'Option 1' },
        { value: "2", label: 'Option 2' },
        { value: '3', label: 'Option 3' },
        { value: '4', label: 'Option 4' },
      ],
      value1: null,
      value2: null,
      value3: null,
      value4: null,
      value5: { value: '4', label: 'Option 4' },
    }
  }
}
</script>